import { useFetch } from 'use-http'
import { getAbsoluteServerUrl } from '../config/config'

type CAText = { id: string; body: string; created_at: Date; }

export function useGetClimateAction() {
  const { data, error, response } = useFetch<CAText[]>(getAbsoluteServerUrl('/climate-action'), { responseType: 'json' }, [])
  if (error) {
    console.error('Error fetching climate action data:', error, response.data)
    return []
  }
  console.log('API response:', response.status, data)
  return data || []
}
